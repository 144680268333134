<template>
  <v-row>
    <overlay :loading="loading" />
    <v-dialog v-model="newRadioDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Novo item </v-card-title>
        <v-form ref="newRadioForm" v-model="validRadioForm" lazy-validation>
          <v-card-text>
            <v-text-field
              label="Nome do campo"
              outlined
              dense
              required
              :rules="[(v) => !!v || 'Nome é obrigatório']"
              v-model="newRadioComponent.text"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              text
              @click="
                () => {
                  newRadioDialog = false;
                  newRadioComponent = { text: null };
                  $refs.newRadioForm.resetValidation();
                }
              "
            >
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="!validRadioForm"
              @click="
                () => {
                  if (!$refs.newRadioForm.validate()) return;
                  newRadioDialog = false;
                  component.config.items.push(
                    Object.assign({}, newRadioComponent)
                  );
                  newRadioComponent = { text: null };
                  $refs.newRadioForm.resetValidation();
                }
              "
            >
              Adicionar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-form ref="formAnamnesisCreate" v-model="validForm" lazy-validation>
        <v-row class="">
          <v-col cols="12" class="subtitle-2 grey--text pt-0">
            {{
              $route.params.data
                ? `Anamnese: ${$route.params.data.name}`
                : "Criação de novo formulário de anamnese"
            }}
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-row>
              <v-col cols="12" class="subtitle-1 font-weight-normal">
                Nome do formulário:
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field
                  placeholder="Insira um nome"
                  outlined
                  dense
                  v-model="form.name"
                  required
                  :rules="[(v) => !!v || 'Nome do formulário é obrigatório']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="auto" class="">
            <v-row class="">
              <v-col cols="12" class="subtitle-1 font-weight-normal pb-2">
                Público:
              </v-col>
              <v-col cols="12" class="">
                <v-radio-group
                  v-model="form.config.public"
                  class="pa-0 ma-0"
                  mandatory
                  row
                >
                  <v-radio label="Ambos" value="both"></v-radio>
                  <v-radio class="" label="Masculino" value="male"></v-radio>
                  <v-radio label="Feminino" value="female"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="auto" class="">
            <v-row class="">
              <v-col cols="12" class="subtitle-1 font-weight-normal pb-2">
                Formulário obrigatório?
              </v-col>
              <v-col cols="12" class="">
                <v-radio-group
                  v-model="form.required"
                  class="pa-0 ma-0"
                  mandatory
                  row
                >
                  <v-radio class="" label="Não" :value="false"></v-radio>
                  <v-radio label="Sim" :value="true"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col
            v-for="(formItem, index) in form.form_items_attributes"
            :key="index"
            cols="12"
            class="pb-0"
          >
            <v-row>
              <v-col cols="12" class="subtitle-1 font-weight-normal pb-0">
                <v-row>
                  <v-col cols="11">
                    {{ formItem.name ? formItem.name : "Insira um nome:" }}
                    <span
                      :class="{
                        'red--text': formItem.required,
                        'grey--text': !formItem.required,
                      }"
                      >*</span
                    >
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end">
                    <v-btn icon @click="onDeleteItem(formItem, index)">
                      <v-icon color="red"> mdi-delete </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                class="pt-0 pb-0"
                v-if="formItem.kind === 'radio'"
              >
                <v-radio-group class="pt-0 pb-0" mandatory row>
                  <v-radio
                    v-for="(item, index) in formItem.config.items"
                    :key="index"
                    class=""
                    disabled
                    :label="item.text"
                    :value="item.text"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                v-if="formItem.kind === 'string'"
                cols="12"
                class="pt-0 pb-0"
              >
                <v-text-field
                  placeholder="Campo de texto"
                  outlined
                  dense
                  :counter="formItem.counter"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                v-if="formItem.kind === 'text'"
                cols="12"
                class="pt-0 pb-0"
              >
                <v-textarea
                  placeholder="Campo de texto"
                  outlined
                  dense
                  :counter="formItem.counter"
                  disabled
                ></v-textarea>
              </v-col>
              <v-col
                v-if="formItem.kind === 'number'"
                cols="12"
                class="pt-0 pb-0"
              >
                <v-text-field
                  placeholder="Campo numérico"
                  outlined
                  dense
                  :counter="formItem.counter"
                  type="number"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col v-if="formItem.kind === 'imc'">
                <imcAnswer
                  @content="bmiResult($event, answersAsHash[formItem.id])"
                />
              </v-col>
              <v-col
                v-if="formItem.kind === 'date'"
                cols="12"
                class="pt-0 pb-0"
              >
                <v-text-field
                  placeholder="dd/mm/aaaa"
                  outlined
                  dense
                  :counter="formItem.counter"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                v-if="formItem.kind === 'multiselect'"
                cols="12"
                class="pt-0 pb-0"
              >
                <v-select
                  :items="formItem.config.items"
                  multiple
                  dense
                  outlined
                  placeholder="Adicione item aos multiselect"
                >
                  <template v-slot:prepend-item>
                    <v-row class="pa-2">
                      <v-col cols="10" sm="11">
                        <v-text-field
                          v-model="newMultiselectItem"
                          outlined
                          dense
                          placeholder="Novo item"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" sm="1">
                        <v-btn
                          width="100%"
                          @click="
                            () => {
                              if (newMultiselectItem)
                                formItem.config = Object.assign({}, formItem.config, {items: formItem.config.items.concat(newMultiselectItem)})
                              newMultiselectItem = null;
                            }
                          "
                          text
                        >
                          <v-icon large>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row class="align-center">
                  <v-col cols="12" sm="4" class="">
                    <v-row>
                      <v-col cols="12" class="subtitle-1 font-weight-normal">
                        Nome do campo: <span class="red--text">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field
                          @keyup="onChangeCode(component.name)"
                          placeholder="Insira um nome"
                          outlined
                          dense
                          v-model="component.name"
                        ></v-text-field>
                      </v-col>
                    </v-row> </v-col
                  ><v-col cols="12" sm="4" class="">
                    <v-row>
                      <v-col cols="12" class="subtitle-1 font-weight-normal">
                        Componente: <span class="red--text">*</span>
                      </v-col>
                      <v-col
                        cols="12"
                        class="pt-0 pb-0"
                        :class="{ 'pb-0': $vuetify.breakpoint.mobile }"
                      >
                        <v-select
                          v-model="component.kind"
                          :items="components"
                          outlined
                          dense
                          placeholder="Selecione um componente"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-row>
                      <v-col
                        cols="12"
                        class="subtitle-1 font-weight-normal pb-2"
                      >
                        Campo obrigatório? <span class="grey--text">*</span>
                      </v-col>
                      <v-col cols="12">
                        <v-radio-group
                          class="ma-0 pa-0"
                          v-model="component.required"
                          row
                        >
                          <v-radio label="Não" :value="false"></v-radio>
                          <v-radio class="" label="Sim" :value="true"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="
                      component.kind == 'string' ||
                      component.kind == 'text' ||
                      component.kind == 'number'
                    "
                    cols="12"
                    sm="4"
                    class=""
                  >
                    <v-row>
                      <v-col cols="12" class="subtitle-1 font-weight-normal">
                        Contador: <span class="grey--text">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field
                          placeholder="Insira um limite de caracteres"
                          outlined
                          dense
                          v-model="component.counter"
                          type="number"
                          v-mask="'#####'"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="
                      component.kind == 'string' ||
                      component.kind == 'text' ||
                      component.kind == 'number'
                    "
                    cols="12"
                    sm="4"
                    class=""
                  >
                    <v-row>
                      <v-col cols="12" class="subtitle-1 font-weight-normal">
                        Valor inicial: <span class="grey--text">*</span>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-0">
                        <v-text-field
                          placeholder="Insira um valor"
                          outlined
                          dense
                          v-model="component.value"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-if="component.kind == 'radio'">
                    <v-row>
                      <v-col cols="12" class="subtitle-1 font-weight-normal">
                        Valores:
                      </v-col>
                      <v-col cols="12" v-if="component.config.items.length > 0">
                        <v-radio-group class="pt-0 pb-0" mandatory row>
                          <v-radio
                            v-for="(item, index) in component.config.items"
                            :key="index"
                            class=""
                            disabled
                            :label="item.text"
                            :value="item.value"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" class="grey--text pt-0 pb-0" v-else
                        >Nenhum item adicionado</v-col
                      >
                      <v-col cols="12">
                        <v-btn class="primary" @click="newRadioDialog = true"
                          >Novo item</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="
                    component = {
                      name: null,
                      kind: null,
                      code: null,
                      config: {
                        public: 'both',
                        rules: [],
                        value: null,
                        items: [],
                      },
                      required: false,
                      date: null,
                      counter: null,
                    }
                  "
                  text
                  outlined
                  width="150"
                >
                  Limpar
                </v-btn>
                <v-btn
                  @click="onInsertFormItem(component)"
                  height="40"
                  width="150"
                  color="primary"
                  :disabled="!component.kind || !component.name"
                >
                  Adicionar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              class="primary"
              @click="
                $route.params.id ? updateAnamnesisForm() : createAnamnesisForm()
              "
              :disabled="!validForm"
              >{{ $route.params.id ? "Salvar" : "Criar" }}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import Overlay from "../../../../components/Overlay/Overlay.vue";
import imcAnswer from "../../../../components/Questions/ImcAnswer.vue";

export default {
  components: {
    imcAnswer,
    Overlay,
  },
  data: () => ({
    loading: false,
    newRadioDialog: false,
    validRadioForm: true,
    validForm: true,
    component: {
      name: null,
      kind: null,
      code: null,
      counter: null,
      required: false,
      mask: null,
      config: {
        public: "both",
        rules: [],
        items: [],
        value: null,
      },
    },
    newRadioComponent: {
      text: null,
    },
    components: [
      {
        text: "Campo de texto simples",
        value: "string",
      },
      {
        text: "Campo de texto em área",
        value: "text",
      },
      {
        text: "Campo numérico",
        value: "number",
      },
      {
        text: "Campo verdadeiro ou falso",
        value: "radio",
      },
      {
        text: "Campo multiselect",
        value: "multiselect",
      },
      {
        text: "Campo data",
        value: "date",
      },
      {
        text: "Cálculo de IMC",
        value: "imc",
      },
    ],
    masks: [
      {
        text: "Telefone",
        value: "phone",
      },
      {
        text: "CPF",
        value: "cpf",
      },
      {
        text: "RG",
        value: "rg",
      },
      {
        text: "Data",
        value: "date",
      },
    ],
    form: {
      name: null,
      status: 0,
      required: false,
      config: {
        public: "both",
      },
      form_items_attributes: [],
    },
    removedItems: [],
    newMultiselectItem: null,
  }),
  mounted() {
    if (this.$route.params.id) this.showAnamnesisForm();
  },
  methods: {
    async showAnamnesisForm() {
      try {
        this.loading = true;
        const response = await this.$axios("forms/" + this.$route.params.id);
        delete Object.assign(response.data, {
          ["form_items_attributes"]: response.data["form_items"],
        })["form_items"];

        if (response.data) this.form = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Get anamnesis form error: ", error);
        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar receber as anamnesis.",
        });
      }
    },
    async createAnamnesisForm() {
      try {
        if (!this.$refs.formAnamnesisCreate.validate()) {
          await this.$swal({
            position: "center",
            showConfirmButton: true,
            icon: "error",
            title: "MIS",
            text: "Por favor, preencha os campos obrigatórios.",
          });

          return;
        }

        this.form.form_items_attributes.forEach((el) => delete el.editable);

        await await this.$axios.post("forms", {
          form: this.form,
        });

        await this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Formulário criado com sucesso",
        });

        this.$router.push({ name: "AdminAnamnesis" });
      } catch (error) {
        console.log("Create anamnesis form error: ", error);
        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error.",
        });
      }
    },
    async updateAnamnesisForm() {
      try {
        if (!this.$refs.formAnamnesisCreate.validate()) {
          await this.$swal({
            position: "center",
            showConfirmButton: true,
            icon: "error",
            title: "MIS",
            text: "Por favor, preencha os campos obrigatórios.",
          });

          return;
        }

        this.form.form_items_attributes = [
          ...this.form.form_items_attributes,
          ...this.removedItems,
        ];

        this.form.form_items_attributes.forEach((el) => delete el.editable);

        await this.$axios.put("forms/" + this.$route.params.id, this.form);
        await this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Formulário editado com sucesso",
        });

        this.$router.push({ name: "AdminAnamnesis" });
      } catch (error) {
        console.log("Update anamnesis error: ", error);
        this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar atualizar o formulário de anamnesis.",
        });
      }
    },
    onInsertFormItem(item) {
      this.form.form_items_attributes.push(item);
      this.component = {
        name: null,
        kind: null,
        code: null,
        counter: null,
        required: false,
        date: null,
        mask: null,
        config: {
          public: "both",
          rules: [],
          items: [],
          value: null,
        },
      };
    },
    onChangeCode(value) {
      this.component.code = value.replaceAll(" ", "_");
    },
    onDeleteItem(item, index) {
      if (this.$route.params.id) {
        this.form.form_items_attributes[index]._destroy = "1";
        this.removedItems.push(this.form.form_items_attributes[index]);
      }

      const filteredAttributes = this.form.form_items_attributes.filter(
        (el) => el.code !== item.code
      );

      this.form.form_items_attributes = this.form.form_items_attributes =
        filteredAttributes;
    },
    setMask(mask) {
      const masks = {
        phone: "(##) # ####-####",
        cpf: "###.###.###-##",
        rg: "#.###.###",
        date: "##/##/####",
      };

      return String(masks[mask]) || "";
    },
    bmiResult(value, result) {
      result.content = value
    },
  },
  computed: {},
};
</script>

<style scoped>
P {
  text-align: center;
}
.remove-effects {
  width: 100px;
  background: transparent;
  border: none;
}
.remove-effects:focus {
  width: 250px;
  border: 0px;
  background: transparent;
  outline: none;
}
</style>
